(function () {
  class BookingWidget {
    constructor(dataEndpoint) {
      this.museumMap = {};
      if (AtlasUtils.isDev()) {
        this.dataEndpoint = AtlasUtils.api() + 'dataproxy';
      } else {
        this.dataEndpoint = AtlasUtils.api() + 'proxy.php';
      }
    }

    getMuseumById(id) {
      return this.museumMap[id];
    }

    loadData(cb) {
      $.getJSON(this.dataEndpoint)
        .done((resp) => {
          let museums = [];
          this.museumMap = {};
          this.guideMap = {};
          this.affiliateCodes = {};
          this.affiliateEmails = {};
          this.affiliateIds = {};
          this.affiliateChildren = [];

          for (let exp of resp.experiences) {
            if (exp.position && exp.position.id) {
              if (AtlasUtils.isAffiliate()) {
                if (exp.desc.match(/\[Affiliate/) || exp.excerpt.match(/\[Affiliate/)) {
                  let parts = exp.originalName.split(':');
                  exp.name = `${parts[0]}: ${parts[parts.length - 1]}`;
                  museums.push(exp);
                  this.museumMap[exp.id] = exp;
                }
              } else {
                museums.push(exp);
                this.museumMap[exp.id] = exp;
              }
            }
          }

          if (AtlasUtils.isAffiliate()) {
            let firstIds = [
              '590201e6332e758a3e8b45ff' /* The Met */,
              '59022127332e75e87b8b4570',
              '58ddae5307876ce2068b45e4' /* Dev */,
            ];
            let lastNames = ['CH', 'Ch', 'DC'];
            museums = museums.sort((a, b) => {
              let aord = firstIds.indexOf(a.id),
                bord = firstIds.indexOf(b.id);
              if (aord >= 0 && bord >= 0) {
                return aord - bord;
              }
              if (aord >= 0 && bord < 0) {
                return -1;
              }
              if (bord >= 0 && aord < 0) {
                return 1;
              }

              let alast = lastNames.indexOf(a.originalName.substr(0, 2)),
                blast = lastNames.indexOf(b.originalName.substr(0, 2));
              if (alast >= 0 && blast < 0) {
                return 1;
              }
              if (blast >= 0 && alast < 0) {
                return -1;
              }

              if (a.name < b.name) {
                return -1;
              }
              if (b.name < a.name) {
                return 1;
              }
              return 0;
            });
          }

          let parentIds = {};
          for (let aff of resp.affiliates) {
            if (aff.children) {
              parentIds[aff.id] = aff;
            }
          }
          for (let aff of resp.affiliates) {
            if (window.xola_affiliate_code) {
              let xac = xola_affiliate_code.toLowerCase();
              let ac = aff.code && aff.code.toLowerCase();
              let pc =
                aff.parent &&
                parentIds[aff.parent] &&
                parentIds[aff.parent].code &&
                parentIds[aff.parent].code.toLowerCase();
              if (ac === xac || pc === xac) {
                this._setAffiliate(aff);
              }
            } else {
              this._setAffiliate(aff);
            }
          }
          resp.experiences = museums;
          this.availability = resp.availability;
          this.user_positions = resp.user_positions;

          for (let pos of this.user_positions) {
            this.guideMap[pos.user.id] = pos.user;
          }

          let positions = {};
          for (let u of resp.user_positions) {
            for (let p in u.positions) {
              if (!positions[p]) {
                positions[p] = { users: [] };
              }
              positions[p].users.push(u);
            }
          }

          cb(null, resp);
        })
        .fail(cb);
    }

    _setAffiliate(aff) {
      this.affiliateIds[aff.id] = aff;
      if (aff.code) {
        this.affiliateCodes[aff.code.toLowerCase()] = aff;
      }
      if (aff.email) {
        this.affiliateEmails[aff.email.toLowerCase()] = aff;
      }
      if (aff.parent) {
        this.affiliateChildren.push(aff);
      }
    }

    getAffiliate(codeOrEmailOrId) {
      if (!codeOrEmailOrId) {
        return null;
      }
      codeOrEmailOrId = codeOrEmailOrId.toLowerCase();
      let aff =
        this.affiliateIds[codeOrEmailOrId] ||
        this.affiliateCodes[codeOrEmailOrId] ||
        this.affiliateEmails[codeOrEmailOrId];
      return aff || null;
    }

    getAffiliateChildren() {
      if (window.xola_affiliate_code) {
        return this.affiliateChildren;
      } else {
        return [];
      }
    }

    toTimeNum(timeStr) {
      var split = timeStr.split(' ');
      var hm = split[0];
      var a = split[1];

      var hmSplit = hm.split(':');
      var h = hmSplit[0];
      var m = hmSplit[1];

      var ret = parseInt(h) * 100 + parseInt(m);
      if (a === 'PM' && ret !== 1200) {
        ret += 1200;
      }
      return ret;
    }

    timeAdd(timeNum, hours) {
      return timeNum + Math.floor(hours) * 100 + (hours % 1) * 60;
    }

    toTimeString(timeNum) {
      let hours = Math.floor(timeNum / 100);
      let minutes = timeNum - hours * 100;
      let a = hours >= 12 ? 'PM' : 'AM';

      if (hours > 12) {
        hours -= 12;
      }
      if (minutes === 0) {
        minutes = '00';
      }

      return hours + ':' + minutes + ' ' + a;
    }

    toHoursMinutes(timeNum) {
      let hours = Math.floor(timeNum / 100);
      let minutes = timeNum - hours * 100;
      return { hours, minutes };
    }

    getAllStartTimes(expId) {
      if (!expId) {
        return [];
      }
      let exp = this.museumMap[expId];
      let times = {};
      if (!exp.schedules || !exp.schedules.length) {
        return [];
      }

      exp.schedules.forEach((s) => {
        if (!s.times || !s.times.length) {
          return;
        }
        s.times.forEach((t) => {
          times[t] = true;
        });
      });

      let timesArr = [];
      for (var k in times) {
        timesArr.push(k);
      }

      timesArr.sort();
      timesArr.forEach((t, i) => {
        timesArr[i] = this.toTimeString(t);
      });

      return timesArr;
    }

    getStartTimesForDate(expId, date) {
      if (!expId || !date) {
        return [];
      }

      let times = this.getAllStartTimes(expId);
      times = times.filter((time) => {
        return this.doesExperienceHaveStartTimeOnDate(expId, date, time);
      });

      return times;
    }

    getAvailableTimesForDate(expId, date) {
      if (!expId || !date) {
        return [];
      }

      let times = this.getStartTimesForDate(expId, date);
      times = times.filter((time) => {
        return !!this.getAvailableGuidesForDateAndTime(expId, date, time).length;
      });
      return times;
    }

    doesExperienceHaveStartTimeOnDate(expId, date, time) {
      let m = this.getMuseumById(expId);
      let t = this.toTimeNum(time);
      let day = date.getDay();

      // check blackout schedules for unavailable date
      for (let s of m.schedules.filter((sch) => sch.type === 'unavailable')) {
        if (s.dates) {
          for (let sd of s.dates) {
            if (moment(date).isSame(sd, 'day')) {
              return false;
            }
          }
        }
      }

      // check remaining schedules
      for (let s of m.schedules.filter((sch) => sch.type === 'available')) {
        // if this date is out of range of schedule
        if (
          (s.start && moment(date).isBefore(s.start, 'day')) ||
          (s.end && moment(date).isAfter(s.end, 'day'))
        ) {
          continue;
        }

        if (s.days && s.days.includes(day) && s.times && s.times.includes(t)) {
          return true;
        }
      }
      return false;
    }

    getEligibleUsersForExperience(expId) {
      let eligible = {};
      let exp = this.museumMap[expId];
      if (!exp) {
        return null;
      }
      let position = exp.position.id;
      let users = this.user_positions;

      for (let user of users) {
        if (position in user.positions) {
          eligible[user.id] = {
            name: user.name,
            id: user.id,
            pref: false,
          };
        }
      }

      if (Object.keys(eligible).length) {
        return eligible;
      } else {
        return null;
      }
    }

    getAvailableGuidesForDateAndTime(expId, date, time, hours) {
      if (!date || !time || !expId) {
        return [];
      }

      var avail = this.availability;
      if (!avail) {
        return [];
      }

      let expAvail = this.doesExperienceHaveStartTimeOnDate(expId, date, time);
      if (!expAvail) {
        return [];
      }

      let eligibleUsers = this.getEligibleUsersForExperience(expId);
      if (!eligibleUsers) {
        return [];
      }

      if (!hours) {
        hours = 2;
      }

      for (let a of avail) {
        a.start_date = new Date(a.start_date);
        a.end_date = new Date(a.end_date);
        if (this.availOverlap(a, date, time, hours, eligibleUsers)) {
          if (a.type === 1 /* unavailable */) {
            delete eligibleUsers[a.user_id];
          } else if (a.type === 2 /* preference */) {
            if (eligibleUsers[a.user_id]) {
              eligibleUsers[a.user_id].pref = true;
            }
          }
        }
      }

      var availGuides = [];
      for (let k in eligibleUsers) {
        availGuides.push(eligibleUsers[k]);
      }

      availGuides = availGuides.sort((a, b) => {
        // Atlas App - should only be here in DEV
        if (a.id === 18658880) {
          return -1;
        }
        if (b.id === 18658880) {
          return 1;
        }
        if (a.pref && !b.pref) {
          return -1;
        }
        if (b.pref && !a.pref) {
          return 1;
        }
        return Math.random() < 0.5 ? 1 : -1;
      });

      return availGuides;
    }

    // returns true if there's any overlap between tour and availability
    // availibility is just a scheduled block -- it could be an available block
    // or an unavailable block.
    availOverlap(avail, date, time, hours, users) {
      if (!avail.user_id in users) {
        return false;
      }

      // generic availability -- ie not date specific
      if (avail.availability_id) {
        if (date >= avail.start_date && date <= avail.end_date && date.getDay() === avail.day) {
          let availStart = this.toTimeNum(avail.start_time);
          let availEnd = this.toTimeNum(avail.end_time);
          if (availEnd === 0) {
            availEnd = 2400;
          }
          let startTime = this.toTimeNum(time);
          let endTime = this.timeAdd(startTime, hours);

          let hasAnyOverlap =
            avail.start_time === avail.end_time || // midnight to midnight - whole day
            (availStart <= startTime && endTime <= availEnd) || // tour exists completely between times
            (startTime <= availStart && endTime > availStart) || // starts before, ends within availability
            (startTime < availEnd && endTime >= availEnd); // starts in availability, ends after

          return hasAnyOverlap;
        }
      }
      // reserved shift or blocked off time
      else {
        let eventStartDate = moment(date);
        let eventStartTime = this.toHoursMinutes(this.toTimeNum(time));
        eventStartDate.add(eventStartTime.hours, 'hours').add(eventStartTime.minutes, 'minutes');

        let eventEndDate = eventStartDate.clone().add(hours, 'hours');

        let availStartDate = moment(avail.start_date);
        let availStartTime = this.toHoursMinutes(this.toTimeNum(avail.start_time));
        availStartDate.add(availStartTime.hours, 'hours').add(availStartTime.minutes, 'minutes');

        let availEndDate = moment(avail.end_date);
        let availEndTime = this.toHoursMinutes(this.toTimeNum(avail.end_time));
        availEndDate.add(availEndTime.hours, 'hours').add(availEndTime.minutes, 'minutes');

        let hasAnyOverlap =
          (availStartDate <= eventStartDate && availEndDate > eventStartDate) ||
          (availEndDate >= eventEndDate && availStartDate < eventEndDate) ||
          (eventStartDate <= availStartDate && availEndDate <= eventEndDate);
        return hasAnyOverlap;
      }
      return false;
    }

    getGuideById(id) {
      return this.guideMap[id];
    }
  }

  window.BookingWidget = BookingWidget;
})();
