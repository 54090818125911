(function () {
  'use strict';

  let cache = {};

  class Translator {
    constructor(opts) {
      this.defaultLocale = 'en';
      opts = Object.assign(
        {
          directory: AtlasUtils.static() + 'locales',
          locale: null,
        },
        opts
      );

      this.locale = opts.locale;
      this.directory = opts.directory;

      if (!this.locale) {
        this.locale = $('html').attr('lang');
        this.locale = this.locale.split('-')[0].split('_')[0];
      }
      if (!this.locale) {
        this.locale = this.defaultLocale;
      }

      this.getLocale(this.defaultLocale);
      if (this.locale !== this.defaultLocale) {
        this.getLocale(this.locale);
      }
    }

    getLocale(locale) {
      return $.ajax({
        url: `${this.directory}/${locale}.json`,
        async: false,
        dataType: 'json',
        contentType: 'application/json',
      }).success((data) => {
        cache[locale] = data;
      });
    }

    __(text, ...args) {
      let newText = text;
      if (cache[this.locale] && cache[this.locale][text]) {
        newText = cache[this.locale][text];
      } else if (cache[this.defaultLocale] && cache[this.defaultLocale][text]) {
        newText = cache[this.defaultLocale][text];
      }

      for (let arg of args) {
        newText = newText.replace('%s', this.__(arg));
      }

      return newText;
    }
  }

  let trans = new Translator();
  window.__t = trans.__.bind(trans);

  window.Translator = Translator;
})();
