var AtlasUtils = (function () {
  'use strict';

  let x = {};

  x.isDev = function () {
    let hostname = window.location.hostname;
    return hostname.indexOf('dev') >= 0 || hostname.indexOf('localhost') >= 0;
  };

  x.isAffiliate = function () {
    let path = window.location.pathname;
    return path.includes('/affiliate/');
  };

  x.api = function () {
    return x.isDev() ? '/' : '/wp-content/plugins/atlas-booking/';
  };

  x.static = function () {
    return x.isDev() ? '/static/' : '/wp-content/plugins/atlas-booking/static/';
  };

  x.randomEmail = function () {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15) +
      '@artsmart.com'
    );
  };

  return x;
})();
