$(document).ready(function () {
  let bw = new BookingWidget();

  let selections = {
    tour: {},
    tourName: null,
    time: null,
    date: null,
    guests: {
      adults: 0,
      children: 0,
    },
    hours: 0,
    customerName: '',
    customerEmail: '',
    notes: [],
    guide: null,
    affiliate: null,
  };

  let required = {
    step3: ['idBookingWidgetFirstName', 'idBookingWidgetLastName', 'idBookingWidgetEmail'],
    step4: [
      'idBookingWidgetCCName',
      'idBookingWidgetCCNumber',
      'idBookingWidgetCCMonth',
      'idBookingWidgetCCYear',
      'idBookingWidgetCCCVV',
    ],
  };

  const ORDER = {
    TimeFirst: 'TimeFirst',
    DateFirst: 'DateFirst',
  };

  let SETTINGS = {
    Order: ORDER.DateFirst,
  };

  let $guideMenu,
    $durationBoxes = {},
    $slider,
    lastSliderValue;

  init();

  function init() {
    if (AtlasUtils.isAffiliate()) {
      SETTINGS.Order = ORDER.DateFirst;
    }

    if (SETTINGS.Order === ORDER.DateFirst) {
      $('#idBookingWidgetTimeFilter').insertAfter('#idBookingWidgetDatepickerWrapper');
    }

    setupAffiliates();
    setupRequired();
    setupDurationBoxes();
    setupSlider();
    selectDuration(4, true);

    $('#idBookingWidgetTermsLink').click(termsClicked);
    $('#idBookingWidgetChooseDurationButton').click(validateStep1AndGoToStep2);
    $('#idBookingWidgetReviewOrder').click(validateStep2AndGoToStep3);
    $('#idBookingWidgetCheckoutButton').click(validateStep3AndGoToStep4);
    $('#idBookingWidgetBookNowButton').click(validateAndProcessStep4);
    $('#idBookingWidgetTermsCheck').click(validateTermsAndConditions);
    $('.step-back').click(onStepBackClicked);
    $('.btn-increment').on('click', btnIncrementClicked);
    $('input.input-number').keydown(onNumberInputKeyDown);

    $('.open-popup-link').magnificPopup({
      type: 'inline',
      closeBtnInside: false,
      midClick: true, // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
    });

    $('#idBookingWidgetDatepicker').datepicker({
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      beforeShowDay: datepickerBeforeShowDay,
      onSelect: datepickerOnSelect,
    });

    bw.loadData(bookingWidgetDataLoaded);
  }

  function bookingWidgetDataLoaded(err, data) {
    if (err) {
      console.error('err:', err);
      return;
    }
    var museums = data.experiences;
    var tourMenu = buildMenu($('#idBookingWidgetTourFilter'), museums);
    tourMenu.on('menuselect', tourMenuSelectionChanged);

    $('#idBookingWidgetTourFilter li').each((ii, el) => {
      let $el = $(el);
      if (window.booking_id == $el.data('custom-id')) {
        $el.click();
      }
    });

    setupAffiliates();
  }

  function tourMenuSelectionChanged(e, ui) {
    selections.tourName = $(ui.item).text();
    selections.tour = bw.getMuseumById($(ui.item).data('custom-id'));

    $('#selectedTour').val(selections.tourName);
    $('#idBookingWidgetDatepicker').datepicker('refresh');

    buildTimeMenu();
    buildDemographics(selections.tour);
    refreshGuideMenu(false);
    validateFirstStep();
  }

  function buildTimeMenu() {
    var timeMenu;
    if (SETTINGS.Order === ORDER.TimeFirst) {
      timeMenu = buildMenu(
        $('#idBookingWidgetTimeFilter'),
        bw.getAllStartTimes(selections.tour && selections.tour.id)
      );
    } else {
      timeMenu = buildMenu(
        $('#idBookingWidgetTimeFilter'),
        bw.getAvailableTimesForDate(selections.tour && selections.tour.id, selections.date)
      );
    }
    timeMenu.on('menuselect', timeMenuSelectionChanged);
  }

  function timeMenuSelectionChanged(e, ui) {
    var timeSpan = document.getElementById('select-time');
    timeSpan.className += ' selected';
    selections.time = $(ui.item).text();
    refreshGuideMenu(false);
    jQuery('#selectedTime').val($(ui.item).text());
    $('#idBookingWidgetDatepicker').datepicker('refresh');
    validateFirstStep();
  }

  function buildMenu(anchor, options) {
    $('ul', anchor).remove();
    var menu = $('<ul class="filter-menu" style="display: none;"></ul>');
    menu.css('width', anchor.css('width'));

    for (var i = 0, n = options.length; i < n; ++i) {
      var o = options[i];
      if (typeof o === 'string') {
        menu.append('<li><div>' + o + '</div></li>');
      } else if (typeof o === 'object') {
        var openingTag = '<li><div>';
        if (o.id) {
          openingTag = '<li data-custom-id="' + o.id + '"><div>';
        }
        menu.append(openingTag + o.name + '</div></li>');
      }
    }
    menu.menu({
      select: function (e, ui) {
        $('span', anchor).text($(ui.item).text());
        menu.hide();
        anchor.attr('style', 'border: 1px solid #eaeaea;');
      },
    });
    anchor.append(menu);
    menu.click(function (e) {
      e.stopPropagation();
    });
    anchor.click(function (e) {
      menu.toggle();
    });
    return menu;
  }

  function buildDemographics(exp) {
    let $demo = $('#TourDemographics');
    $demo.empty();

    let rates = getDemoRates(exp);

    for (let ii in rates) {
      let rate = rates[ii];

      //<span class="inline-price">$<span class="the-amount" data-initialtotal="${rate.initial}">${rate.initial}</span></span>

      $demo.append(`
        <div id="TourDemo_${rate.code}" class="TourDemo input-group">
          <div class="label">
            <span class="summary title">${rate.description}</span>
            
          </div>
          <div class="thirdfull first">
            <input type="text" name="quant[1]"
                   class="filter-btn input-number" value="${rate.initial}"
                   disabled data-min="0" data-max="10">
          </div> 
          <div class="third last">
            <div class="filter-btn minus">
              <button type="button" class="minus btn-increment" data-type="minus" data-field="quant[1]">
                <i class="fa fa-minus" aria-hidden="true"></i>
              </button>
            </div>
            <div class="filter-btn plus">
              <button type="button" class="plus btn-increment" data-type="plus" data-field="quant[1]">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        </div>
      `);
    }
    $('.TourDemo .btn-increment').click(btnIncrementClicked);
  }

  function datepickerBeforeShowDay(day) {
    let tomorrow = moment().add(1, 'day');
    if (day < tomorrow.toDate()) {
      return [false, 'no-available-tours-date'];
    }

    if (SETTINGS.Order === ORDER.TimeFirst) {
      // set to the minimum hours because there might be a guide available for 2 but none for 4,
      // otherwise this would block out the date completely.
      let hours = 2;
      let ag = bw.getAvailableGuidesForDateAndTime(selections.tour.id, day, selections.time, hours);
      if (ag.length) {
        return [
          true,
          'available-tours-date',
          ag.length + ' guides available:&#013;' + ag.map((a) => a.name).join('&#013;'),
        ];
      } else {
        return [false, 'no-available-tours-date', 'No tours available'];
      }
    } else {
      let times = bw.getAvailableTimesForDate(selections.tour.id, day);
      if (times.length) {
        return [
          true,
          'available-tours-date',
          `${times.length} times available:&#013;${times.join('&#013;')}`,
        ];
      } else {
        return [false, 'no-available-tours-date', 'No tours available'];
      }
    }
  }

  function datepickerOnSelect(dateText, inst) {
    selections.date = new Date(dateText);
    buildTimeMenu();
    refreshGuideMenu(false);

    validateFirstStep();
  }

  function buildGuideMenu() {
    let ag = bw.getAvailableGuidesForDateAndTime(
      selections.tour.id,
      selections.date,
      selections.time,
      selections.hours
    );

    $guideMenu = buildMenu($('#idBookingWidgetGuideSelect'), ag);
    $guideMenu.on('menuselect', function (e, ui) {
      let $item = $(ui.item),
        $id = $item.data('custom-id');
      selections.guide = bw.getGuideById($id);
      setGuideInfo(selections.guide);
    });

    return ag;
  }

  function refreshGuideMenu(keepSelected) {
    let ag = buildGuideMenu();

    let $select = $('span', $('#idBookingWidgetGuideSelect'));
    if (!ag.length) {
      let text = `-- ${__t(
        'No guides available for %s hours at %s',
        selections.hours,
        selections.time
      )}`;
      $select.text(text).data('custom-id', '');
      selections.guide = null;
      $('#idBookingWidgetReviewOrder').addClass('disabled').attr('');
    } else {
      $('#idBookingWidgetReviewOrder').removeClass('disabled');
      let stillValid = false;
      for (let aa of ag) {
        if (selections.guide && selections.guide.id == aa.id) {
          stillValid = true;
        }
      }
      if (!stillValid || !keepSelected) {
        $select.text(ag[0].name).data('custom-id', ag[0].id);
        selections.guide = bw.getGuideById(ag[0].id);
        setGuideInfo(selections.guide);
      }
    }
  }

  function setGuideInfo(guide) {
    $('#guide-info h4').text(guide.first_name);
    $('#guide-info p').text(guide.notes);
    let small = guide.avatar.url.replace(guide.avatar.size, 'small');
    let large = guide.avatar.url.replace(guide.avatar.size, 'medium');
    $('#idGuidePhoto').attr('src', small);
    $('#guide-info img').attr('src', large);
  }

  function validateFirstStep() {
    if (selections.time && selections.tourName && selections.date) {
      $('#idBookingWidgetChooseDurationButton').removeClass('disabled');
      return true;
    } else {
      return false;
    }
  }

  function termsClicked(e) {
    e.preventDefault();

    let terms = '';
    if (selections.tour.terms) {
      terms = selections.tour.terms.termsHuman || selections.tour.terms.termsLegal;
    }

    $('#terms-and-conditions p').text(terms);
  }

  function validateStep1AndGoToStep2(e) {
    e.preventDefault();

    if (!validateFirstStep()) {
      return;
    }
    if (!selections.time || !selections.tourName || !selections.date) {
      return;
    }
    if ($('#idBookingWidgetChooseDurationButton').hasClass('disabled')) {
      return;
    }

    var date_r = $('#idBookingWidgetDatepicker').datepicker('getDate');
    $('#selectedDate').val($.datepicker.formatDate('M, dd/yy', date_r));
    $('#idBookingWidgetStep1').fadeOut(300);
    setTimeout(function () {
      $('#idBookingWidgetStep2').show();
      scrollTo('bookingwrap');
      scrollToDuration('dur-4');
    }, 300);
  }

  function validateStep2AndGoToStep3(e) {
    e.preventDefault();
    if ($('#idBookingWidgetReviewOrder').hasClass('disabled')) {
      return;
    }
    $('#idBookingWidgetStep2').fadeOut(300);
    setTimeout(function () {
      $('#idBookingWidgetStep3').show();
      scrollTo('bookingwrap');
      set_price_total(true);
    }, 300);
  }

  function validateAffiliate() {
    if (AtlasUtils.isAffiliate()) {
      let $aff;
      let codes = bw.getAffiliateChildren();
      let isEntry = true;
      if (codes && codes.length) {
        $aff = $('#idBookingWidgetAffiliateDropdown');
        isEntry = false;
      } else {
        $aff = $('#idBookingWidgetAffiliateCode');
      }

      let $affiliateError = $('#idBookingWidgetAffiliateError');
      let $bookingError = $('.bookingWidgetErrorMessage');

      $aff.removeClass('error');
      $affiliateError.text('');
      $bookingError.text('');

      if (isEntry) {
        let entryText = $aff.val();
        if (!entryText) {
          setMissingFieldsWarning(true);
          return false;
        }
        let affiliate = bw.getAffiliate(entryText);

        if (!affiliate) {
          $aff.addClass('error');
          let errmsg = __t('Code/Email does not match a known affiliate.');
          $affiliateError.text(errmsg);
          $bookingError.text(errmsg);
          return false;
        }
      } else {
        if (!selections.affiliate) {
          $aff.addClass('error');
          setMissingFieldsWarning(true);
        }
      }
    }
    return true;
  }

  function validateStep3AndGoToStep4(e) {
    e.preventDefault();
    if ($('#idBookingWidgetCheckoutButton').hasClass('disabled')) {
      return;
    }

    let f = $('#idBookingWidgetFirstName').val(),
      l = $('#idBookingWidgetLastName').val();
    let full = f.trim() + ' ' + l.trim();

    if (!$('#idBookingWidgetCCName').val().trim()) {
      $('#idBookingWidgetCCName').val(full);
    }

    if (!validateStep('step3')) {
      return;
    }
    if (!validateAffiliate()) {
      return false;
    }
    set_price_total();

    $('#idBookingWidgetStep3').fadeOut(300);
    setTimeout(function () {
      $('#idBookingWidgetStep4').show();
      scrollTo('bookingwrap');
    }, 300);
  }

  function validateTermsAndConditions(e) {
    if (!e.target.checked) {
      $('#idBookingWidgetBookNowButton').addClass('disabled');
    } else {
      $('#idBookingWidgetBookNowButton').removeClass('disabled');
    }
  }

  function validateAndProcessStep4(e) {
    e.preventDefault();

    if ($('#idBookingWidgetBookNowButton').hasClass('disabled')) {
      return;
    }

    if (!$('#idBookingWidgetTermsCheck').prop('checked')) {
      return;
    }

    if (!validateStep('step4')) {
      return;
    }

    $('#idBookingWidgetBookNowButton').addClass('disabled');
    $('#idBookingWidgetError').text('');

    let customer = {
      name:
        $('#idBookingWidgetFirstName').val().trim() +
        ' ' +
        $('#idBookingWidgetLastName').val().trim(),
      email: $('#idBookingWidgetEmail').val(),
      phone: $('#idBookingWidgetPhone').val(),
      cc_name: $('#idBookingWidgetCCName').val(),
      cc_number: $('#idBookingWidgetCCNumber').val(),
      cc_exp_month: $('#idBookingWidgetCCMonth').val(),
      cc_exp_year: $('#idBookingWidgetCCYear').val(),
      cc_cvv: $('#idBookingWidgetCCCVV').val(),
      cc_zip: $('#idBookingWidgetCCZip').val(),
    };

    let notes = [];
    let hear = $('#idBookingWidgetHear').val(),
      note = $('#idBookingWidgetNotes').val();
    if (hear) {
      notes.push('How did you hear about us? ' + hear);
    }
    if (note) {
      notes.push('Notes: ' + note);
    }

    let order = new XolaOrder({
      experience_id: selections.tour.id,
      experienceName: selections.tour.name,
      affiliate: selections.affiliate && selections.affiliate.code,
      arrival: moment(selections.date).format('YYYY-MM-DD'),
      arrivalTime: bw.toTimeNum(selections.time),
      quantity: selections.guests.adults + selections.guests.children,
      adults: selections.guests.adults,
      children: selections.guests.children,
      customerName: customer.name,
      customerEmail: customer.email,
      customerPhone: customer.phone,
      notes: notes,
      hours: selections.hours,
      guide: selections.guide,
      position: selections.tour.position,
      site: selections.tour.site,
      location: selections.tour.location,
      cc: {
        number: customer.cc_number,
        cvv: customer.cc_cvv,
        expiryMonth: customer.cc_exp_month.split(':')[0],
        expiryYear: customer.cc_exp_year,
        billingName: customer.cc_name,
        billingZip: customer.cc_zip,
      },
    });

    order
      .send()
      .then((res) => {
        $('#idBookingWidgetStep4').fadeOut(300);
        setTimeout(function () {
          $('#idBookingWidgetStep5').show();
          scrollTo('bookingwrap');
        }, 300);
      })
      .catch((err) => {
        $('#idBookingWidgetBookNowButton').removeClass('disabled');

        $('.bookingWidgetErrorMessage').text(err.message);
      });
  }

  function onStepBackClicked(e) {
    e.preventDefault();
    let $step = jQuery(this).parents('.bookingwrap');
    $step.fadeOut(300, function () {
      $step.prev().fadeIn(300);
    });
  }

  function setupAffiliates() {
    if (AtlasUtils.isAffiliate()) {
      required.step3.splice(required.step3.indexOf('idBookingWidgetEmail'), 1);
      replaceAffiliateFields();
      let codes = bw.getAffiliateChildren();
      if (codes && codes.length) {
        $('#idBookingWidgetAffiliateEntry').hide();
        $('#idBookingWidgetAffiliateDropdown').show();
        buildAffiliateContainer(codes);
      } else {
        $('#idBookingWidgetAffiliateEntry').show();
        $('#idBookingWidgetAffiliateDropdown').hide();
        $('#idBookingWidgetAffiliateEntry').change(onAffiliateEntryChanged);
      }
      $('#book-summary-affiliate-wrap').show();
      $('#book-summary-noaffiliate-wrap').hide();
    } else {
      $('#idBookingWidgetAffiliateBlock').hide();
      $('#idBookingWidgetPhone').hide();
      $('#book-summary-affiliate-wrap').hide();
      $('#book-summary-noaffiliate-wrap').show();
    }
  }

  function replaceAffiliateFields() {
    $('#idBookingWidgetFirstName').attr('placeholder', 'Client First Name *');
    $('#idBookingWidgetLastName').attr('placeholder', 'Client Last Name *');
    $('#idBookingWidgetEmail').attr(
      'placeholder',
      'Optional: Client Email Address (Do NOT submit concierge/agent address)'
    );
    $('#idBookingWidgetPhone').attr('placeholder', 'Phone Number to reach guest on day of tour');
    $('#idBookingWidgetHear').hide();
    let notesText = $('#idBookingWidgetNotes').attr('placeholder');
    notesText = (notesText || '').replace('your', 'the');
    $('#idBookingWidgetNotes').attr('placeholder', notesText);
    $('#idBookingWidgetSelectTour').text('Click to select Tour');
    $('#select-time').text('Click on date above, then select an available time');
  }

  function buildAffiliateContainer(codes) {
    let $aff = $('#idBookingWidgetAffiliateDropdown');
    let menuCodes = codes.map((c) => {
      c.name = `${c.code} - ${c.name}`;
      return c;
    });
    let affMenu = buildMenu($aff, menuCodes);
    affMenu.on('menuselect', affiliateMenuSelectionChanged);
  }

  function affiliateMenuSelectionChanged(e, ui) {
    selections.affiliate = bw.getAffiliate($(ui.item).data('custom-id'));
    validateAffiliate();
    set_price_total();
  }

  function onAffiliateEntryChanged(e) {
    let $aff = $('#idBookingWidgetAffiliateCode');
    let aff = bw.getAffiliate($aff.val());
    selections.affiliate = aff;
    validateAffiliate();
    set_price_total();
  }

  function setupRequired() {
    for (let req of required.step3) {
      let $req = $('#' + req);
      $req.change(function () {
        if ($(this).val()) {
          $(this).removeClass('error');
        }
      });
    }
    for (let req of required.step4) {
      let $req = $('#' + req);
      $req.change(function () {
        if ($(this).val()) {
          $(this).removeClass('error');
        }
      });
    }
  }

  function validateStep(step) {
    let missing = false;
    for (let req of required[step]) {
      let $req = $('#' + req);
      if (!$req.val()) {
        $req.addClass('error');
        missing = true;
      } else {
        $req.removeClass('error');
      }
    }
    setMissingFieldsWarning(missing);

    return !missing;
  }

  function setMissingFieldsWarning(missing) {
    if (missing) {
      $('.bookingWidgetErrorMessage').text(__t('* Please fill in all required fields'));
    } else {
      $('.bookingWidgetErrorMessage').text('');
    }
  }

  function btnIncrementClicked() {
    var $button = $(this);
    var the_input = $button.parents('.input-group').find('input.input-number');
    var oldValue = $button.parents('.input-group').find('input.input-number').val();
    var per_amount = $button.parents('.input-group').find('.the-amount');
    var the_amount = parseFloat(
      $button.parents('.input-group').find('.the-amount').attr('data-initialtotal')
    );

    if ($button.hasClass('plus')) {
      var newVal = parseFloat(oldValue) + 1;
      //per_amount.text(parseFloat(the_amount*newVal));
    } else {
      // Don't allow decrementing below zero
      if (oldValue > 0) {
        var newVal = parseFloat(oldValue) - 1;
      } else {
        newVal = 0;
        //per_amount.text(parseFloat(the_amount));
      }
    }
    if (newVal < the_input.attr('data-min')) return false;
    if (newVal > the_input.attr('data-max')) return false;

    $button.parents('.input-group').find('input.input-number').val(newVal);
    set_price_total(true);
  }

  function onNumberInputKeyDown(e) {
    // Allow: backspace, delete, tab, escape, enter and .
    if (
      $.inArray(e.keyCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
      // Allow: Ctrl+A, Command+A
      (e.keyCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
      // Allow: home, end, left, right, down, up
      (e.keyCode >= 35 && e.keyCode <= 40)
    ) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }

  function setupDurationBoxes() {
    for (var i = 2; i <= 6; ++i) {
      var $box = $('.duration-panel-container[data-duration=' + i + ']');
      (function (iClosured) {
        $box.click(function () {
          selectDuration('' + iClosured, true);
        });
      })(i);
      $durationBoxes[i] = $box;
    }

    // 2.5 is special
    var $box = $('.duration-panel-container[data-duration="2.5"]');
    $box.click(function () {
      selectDuration('2.5', true);
    });
    $durationBoxes['2.5'] = $box;
  }

  function resetDurationBoxes() {
    for (var k in $durationBoxes) {
      var $box = $durationBoxes[k];
      $box.css('opacity', '');
      $box.removeClass('active-box');
    }
  }

  function setupSlider() {
    $slider = $('#idBookingWidgetDurationSlider').slider({
      min: 2,
      value: 4,
      max: 6,
      step: 0.01,
      slide: function (event, ui) {
        var v = parseFloat(ui.value);
        if (v >= 2.25 && v < 2.75) {
          v = 2.5;
        } else {
          v = v.toFixed(0);
        }
        if (lastSliderValue && v === lastSliderValue) {
          return;
        }

        $('#idBookingWidgetDurationSlider .ui-slider-handle').text(v);
        jQuery('#Tourlength .input-number').val(parseFloat(v));
        resetDurationBoxes();
        selectDuration(v);
        lastSliderValue = v;
      },
    });
  }

  function scrollToDuration(elem) {
    if (jQuery('#duration-panel-wrapper').length > 0) {
      document.getElementById('duration-panel-wrapper').scrollTop = 0;
      var topPos = jQuery('#' + elem);

      var childPos = topPos.offset();
      var parentPos = topPos.parents('#duration-panel-wrapper').offset();
      var childOffset = {
        top: childPos.top - parentPos.top,
        left: childPos.left - parentPos.left,
      };

      document.getElementById('duration-panel-wrapper').scrollTop = parseInt(
        childOffset.top - topPos.outerHeight() / 100
      );
    }
  }

  function scrollTo(elem) {
    $('html, body').animate({
      scrollTop:
        $('#' + elem).offset().top -
        $('#' + elem)
          .parent()
          .offset().top /
          4,
    });
  }

  function selectDuration(hours, setSlider) {
    selections.hours = typeof hours === 'string' ? parseFloat(hours) : hours;

    resetDurationBoxes();
    let $box = $durationBoxes[hours];
    $box.css('opacity', '1');
    $box.addClass('active-box');

    scrollToDuration($box.attr('id'));

    $('#Tourlength .input-number').val(hours);
    if (setSlider) {
      $slider.slider('value', hours);
      $('#idBookingWidgetDurationSlider .ui-slider-handle').text(hours);
    }

    refreshGuideMenu(true);
  }

  function getDemoRates(exp) {
    let rates = {
      adults: {
        description: __t('Adults'),
        price: 50,
        code: 'adults',
        initial: 2,
      },
      children: {
        description: __t('Children (18 & Under)'),
        price: 25,
        code: 'children',
        initial: 0,
      },
    };

    for (let addon of exp.addOns) {
      let name = addon.name.toLowerCase();
      if (name.includes('child') && name.includes('rate')) {
        rates.children.price = addon.price * 2;
      } else if (name.includes('adult') && name.includes('rate')) {
        rates.adults.price = addon.price * 2;
      }
    }

    return rates;
  }

  function getSmallGroupRate(exp) {
    for (let addon of exp.addOns) {
      let name = addon.name.toLowerCase();
      if (name.includes('small group') && name.includes('rate')) {
        return addon.price * 2;
      }
    }
  }

  function getDemoBreakdown(rates, hours, totalPeople) {
    let paidHours = hours > 5 ? 5 : hours;

    let totalPrice = 0;
    let breakdown = [];

    if (totalPeople <= 4) {
      totalPrice = rates.smallGroup * Math.min(hours, 5);

      let desc = __t('%s hours @ $%s/hour (up to 4 guests)', paidHours, rates.smallGroup);
      if (hours > 5) {
        desc += ' ' + __t('(6th hour free!)');
      }
      breakdown = [[desc, formatPrice(totalPrice)]];
    } else {
      for (let ii in rates.demo) {
        let demo = rates.demo[ii];
        let demoPrice = demo.count * demo.price * Math.min(hours, 5);
        totalPrice += demoPrice;
        if (demo.count) {
          let desc = __t(
            '%s %s x %s hours @ $%s/pp/hour',
            demo.count,
            demo.code,
            paidHours,
            demo.price
          );

          if (hours > 5) {
            desc += ' ' + __t('(6th hour free!)');
          }
          breakdown.push([desc, formatPrice(demoPrice)]);
        }
      }
      if (totalPrice < 2 * rates.smallGroup) {
        totalPrice = 2 * rates.smallGroup;
        let desc = __t('%s hours @ $%s/hour min.', paidHours, rates.smallGroup);

        if (hours > 5) {
          desc += ' ' + __t('(6th hour free!)');
        }
        breakdown = [[desc, formatPrice(totalPrice)]];
      }
    }

    return {
      breakdown,
      ratePrice: totalPrice,
    };
  }

  function calculateCommission(totalPrice) {
    let comm = {
      amount: 0,
      isCommission: false,
      desc: '',
    };
    if (!selections.affiliate || !totalPrice) {
      return comm;
    }

    let aff = selections.affiliate;
    let affcomm = aff.commission;
    if (affcomm && affcomm.amountType === 'percent' && affcomm.scopeModifier === 'includeAddon') {
      comm.isCommission = true;
      let amount = affcomm.amount;
      comm.desc = amount + '%';
      comm.amount = (amount / 100) * totalPrice;
    }

    return comm;
  }

  function getTicketBreakdown(selections, exp) {
    let childTicket, adultTicket, generalTicket;

    for (let addon of exp.addOns) {
      let name = addon.name.toLowerCase();
      if (name.includes('adult') && name.includes('ticket')) {
        adultTicket = addon;
      } else if (name.includes('child') && name.includes('ticket')) {
        childTicket = addon;
      } else if (name.includes('ticket')) {
        generalTicket = addon;
      }
    }

    if (!adultTicket && !generalTicket) {
      return {
        ticketPrice: 0,
        ticketBreakdown: [],
      };
    }

    let ticketPrice = 0;
    let breakdown = [];

    let adults = selections.guests.adults;
    let children = selections.guests.children;
    if (children && !childTicket) {
      adults += children;
    }

    if (adults && (adultTicket || generalTicket)) {
      let ticket = adultTicket || generalTicket;
      let adultPrice = adults * ticket.price;
      ticketPrice += adultPrice;
      breakdown.push([
        `${adults} ${ticket.name} (${formatPrice(ticket.price)})`,
        formatPrice(adultPrice),
      ]);
    }
    if (children && childTicket) {
      let childPrice = children * childTicket.price;
      ticketPrice += childPrice;
      breakdown.push([
        `${children} ${childTicket.name} (${formatPrice(childTicket.price)})`,
        formatPrice(childPrice),
      ]);
    }

    return {
      ticketPrice,
      ticketBreakdown: breakdown,
    };
  }

  function set_price_total() {
    var e = selections.tour;
    var rates = {
      base: e.price,
      demo: getDemoRates(e),
      smallGroup: getSmallGroupRate(e),
    };

    let hours = selections.hours;

    let totalPeople = 0;

    for (let ii in rates.demo) {
      let demo = rates.demo[ii];
      demo.count = parseInt($(`#TourDemo_${demo.code} .input-number`).val()) || 0;
      totalPeople += demo.count;
      if (demo.code === 'adults') {
        selections.guests.adults = demo.count;
      } else if (demo.code === 'children') {
        selections.guests.children = demo.count;
      }
    }

    let { breakdown, ratePrice } = getDemoBreakdown(rates, hours, totalPeople);
    let { ticketBreakdown, ticketPrice } = getTicketBreakdown(selections, e);

    let totalPrice = ratePrice + ticketPrice;

    let booking_fee = totalPrice * 0.03;
    booking_fee = Math.round(booking_fee * 100) / 100;

    breakdown = breakdown.concat(ticketBreakdown);

    breakdown.push(['3% Credit Card fee', formatPrice(booking_fee)]);

    totalPrice = totalPrice + booking_fee;

    let $pbreak = $('#idPriceBreakdown');
    $pbreak.empty();
    $pbreak.append(
      `
      <table style="width: 80%; margin-left: 10%; margin-right: 10%;">
        <thead>
          <tr>
            <th>Breakdown</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>` +
        breakdown
          .map((b) => {
            return `<tr ${b[1].substr(0, 1) === '-' ? "class='discount'" : ''}><td>${
              b[0]
            }</td><td class="inline-price">${b[1]}</td></tr>`;
          })
          .join('') +
        `
        </tbody>
      </table>
    `
    );

    $('.finalprice').text(formatPrice(totalPrice));
    $('#idBookingWidgetBookNowButtonPrice').text(formatPrice(totalPrice));

    let comm = calculateCommission(totalPrice);

    if (AtlasUtils.isAffiliate()) {
      $('#book-summary-affiliate-wrap').show();
      $('#book-summary-noaffiliate-wrap').hide();

      $('#commissionrate').text(comm.desc);
      $('#finalcommission').text(formatPrice(comm.amount));
    } else {
      $('#book-summary-affiliate-wrap').hide();
      $('#book-summary-noaffiliate-wrap').show();
    }
  }

  function formatPrice(price) {
    return (
      '$' +
      parseFloat(price, 10)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
        .toString()
    );
  }
});
