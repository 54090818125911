(function () {
  'use strict';

  class XolaOrder {
    constructor(options) {
      this.API = {
        Xola: AtlasUtils.isDev() ? 'https://sandbox.xola.com/api/' : 'https://xola.com/api/',
        CreateOrder: AtlasUtils.isDev() ? '/createorder.php' : AtlasUtils.api() + 'createorder.php',
      };

      this.options = Object.assign(
        {
          experience_id: null,
          arrival: null,
          arrivalTime: null,
          quantity: 0,
          adults: 0,
          children: 0,
          customerName: '',
          customerEmail: '',
          customerPhone: '',
          notes: [],
          hours: 0,
          guide: {},
          position: {},
          site: {},
          location: {},
          cc: {
            number: null,
            cvv: null,
            expiryMonth: null,
            expiryYear: null,
            billingName: null,
            billingZip: null,
          },
        },
        options
      );
      this.cc = this.options.cc;
      delete this.options.cc;

      this.order = {};
      this.MIN = 400;
    }

    postJson(url, data) {
      return new Promise((resolve, reject) => {
        jQuery
          .ajax({
            type: 'POST',
            url: url,
            data: JSON.stringify(data),
            contentType: 'application/json',
          })
          .done(resolve)
          .fail(reject);
      });
    }

    getJson(url, params) {
      return new Promise((resolve, reject) => {
        $.ajax({
          type: 'GET',
          url: url,
          data: params,
          contentType: 'application/json',
        })
          .done(resolve)
          .fail(reject);
      });
    }

    parseXolaError(e) {
      if (e && e.responseJSON) {
        let err = e.responseJSON;
        if (err.global) {
          return { log: e.status !== 400, msg: `Error: ${err.global[0]}` };
        }
        if (err.field && err.field.hasOwnProperty('open')) {
          return {
            log: false,
            msg: 'Error: This date and/or time is closed. Please try a different date or contact our office.',
          };
        }
        if (err.message) {
          return { log: true, msg: `Error: ${err.message}` };
        }
      }
      if (e && e.responseText) {
        return { log: true, msg: `Error: ${e.responseText}` };
      }
      return { log: true, msg: 'Error: Something went wrong with your order.' };
    }

    experience() {
      return this.getJson(`${this.API.Xola}experiences/${this.options.experience_id}`).then((e) => {
        this.experience = e;
        return e;
      });
    }

    buildOrder() {
      this.order = {
        arrival: this.options.arrival,
        arrivalTime: this.options.arrivalTime,
        demographics: this.experience.demographics,
        experience: { id: this.experience.id },
        customerName: this.options.customerName,
        customerEmail: this.options.customerEmail || AtlasUtils.randomEmail(),
        customerPhone: this.options.customerPhone,
        children: this.options.children,
        adults: this.options.adults,
        hours: this.options.hours,
        notes: this.options.notes,
        addOns: this.experience.addOns,
        cardDetails: this.cc,
        guide: this.options.guide,
      };
    }

    send() {
      return this.experience()
        .then(() => {
          this.buildOrder();

          return this.postJson(this.API.CreateOrder, this.order);
        })
        .catch((e) => {
          let { msg } = this.parseXolaError(e);
          throw new Error(msg);
        });
    }
  }

  window.XolaOrder = XolaOrder;
})();
